import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { createUseStyles } from 'react-jss'
import { StaticImage } from 'gatsby-plugin-image'

const TermsPage = (props) => {
  const classes = useStyles()
  const { translations, path, } = props
  return (
  <Layout path={path}>
    <Seo title={translations.page_title} description={translations.page_description} />
      <div className={classes.pageTitle}>
        <div className={classes.pageTitleWrapper}>
          <h1>{translations.page_title}</h1>
        </div>
      </div>
      <div className={classes.policyPage}>
        <p className={classes.policyText}>
          {translations.section_1.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_1.part_1} <br />
          {translations.section_1.part_2} <br />
          {translations.section_1.part_3} <br />
          {translations.section_1.part_4} <br />
          {translations.section_1.part_5} <br />
          <br />
          {translations.section_1.part_6} <br />
        </p>

        <p className={classes.policyText}>
          {translations.section_2.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_3.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_4.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_5.title}
          {' '}<a href="/">{translations.section_5.home_link}</a>
        </p>

        <p className={classes.policyText}>
          {translations.section_6.title}
        </p>
        <div className={classes.creditCards}>
          <StaticImage
            className={classes.logo}
            src="../images/dotpay.webp"
            alt={translations.logo_alt}
            height={75}
            quality={30}
          />
          <StaticImage
            className={classes.logo}
            src="../images/24_logo.webp"
            alt={translations.logo_alt}
            height={75}
            quality={30}
          />
        </div>
        <p className={classes.policyText}>
          {translations.section_6.part_1} <br />
          <br />
          {translations.section_6.part_2} <br />
          {translations.section_6.part_3} <br />
          {translations.section_6.part_4} <br />
          {translations.section_6.part_5} <br />
          <br />
          <a href={translations.section_6.part_6}>{translations.section_6.part_6}</a> <br />
        </p>

        <p className={classes.policyText}>
          {translations.section_7.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_8.title}
        </p>


        <p className={classes.policyText}>
          {translations.section_9.title}
        </p>
        <p className={classes.policyText}>
          <ul>
            <li>{translations.section_9.part_1}</li>
            <li>{translations.section_9.part_2}</li>
            <li>{translations.section_9.part_3}</li>
            <li>{translations.section_9.part_4}</li>
          </ul>
        </p>

        <p className={classes.policyText}>
          {translations.section_10.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_11.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_12.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_13.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_14.title}
        </p>


        <p className={classes.policyText}>
          {translations.section_15.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_16.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_16.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_17.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_18.title}
        </p>

        <p className={classes.policyText}>
          {translations.section_19.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_20.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_21.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_22.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_23.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_24.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_25.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_26.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_26.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_28.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_29.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_30.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_31.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_32.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_33.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_34.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_35.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_36.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_37.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_38.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_39.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_40.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_41.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_42.title}
        </p>
      </div>
  </Layout>
  )
}

TermsPage.propTypes = {
  translations: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,

}

const useStyles = createUseStyles({
  policyPage: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '1em',
  },
  pageTitle: {
    backgroundColor: '#c1c2c4',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '30px',

  },
  pageTitleWrapper: {
    margin: 'auto',
    maxWidth: '1221px',
    width: '100%',
    '& h1': {
      fontSize: '30px',
      fontWeight: '400',
      marginLeft: '1em',
    },
  },
  policyText: {
    margin: '20px 0',
    lineHeight: '120%',
    fontSize: '20px',
    color: '#535a57',
    '& a': {
      color: '#ff6f3a',
      textDecoration: 'none',
    },
  },
  creditCards: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.terms_page,
  }
}

export default connect(mapStateToProps)(TermsPage)
